import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { Button, Form, FormGroup, Modal, Image } from 'react-bootstrap';
import ClipLoader from 'react-spinners/BarLoader';
import './style.css';

const EmailForm = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm('service_w3kyypn', 'template_qal0npo', form.current, 'RvFYf43MBrpUa8hMa')
      .then(() => {
          setLoading(false);
          setShow(true);
      }, (error) => {
          setLoading(false);
          console.log('FAILED...', error.text);
          alert('Failed to Send Message');
      });

    e.target.reset(); // Reset form after submission
  };

  const handleClose = () => {
    setShow(false);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  const handleChange = (param, e) => {
    setFormData({ ...formData, [param]: e.target.value });
  };

  return (
    <>
      <Form ref={form} onSubmit={sendEmail} className="Contact-form">
        <p className="contact-form-header">Contact Form</p>
        <FormGroup className="contact_input">
          <Form.Control
            type="text"
            name="from_name"
            value={formData.name}
            className="contact_input_text"
            onChange={(e) => handleChange('name', e)}
            placeholder="Your Name"
            required
          />
        </FormGroup>
        <FormGroup className="contact_input">
          <Form.Control
            type="email"
            name="from_email"
            value={formData.email}
            className="contact_input_text"
            onChange={(e) => handleChange('email', e)}
            placeholder="Email Address"
            required
          />
        </FormGroup>
        <FormGroup className="contact_input">
          <Form.Control
            as="textarea"
            rows="1"
            name="subject"
            value={formData.subject}
            onChange={(e) => handleChange('subject', e)}
            placeholder="Subject"
            className="contact_input_text"
            required
          />
        </FormGroup>
        <FormGroup className="contact_input">
          <Form.Control
            as="textarea"
            rows="6"
            name="message"
            value={formData.message}
            placeholder="Your text"
            onChange={(e) => handleChange('message', e)}
            className="contact_input_text"
            required
          />
        </FormGroup>
        <Button className="contact-email-text-btn" variant="outline-light" size="lg" type="submit">
          Submit
          <ClipLoader size={5} color={"#ffffff"} loading={loading} />
        </Button>
      </Form>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="contact_success_modal_body">
          <Image className="contact_success_modal_img" src="https://icon-library.net/images/success-icon/success-icon-5.jpg" />
          <h5>Thank you <span><strong>{formData.name}</strong>!!</span> 😇</h5>
          <h6>Your message was successfully recorded</h6>
          <br />
          <Button variant="outline-light" size="lg" onClick={handleClose} className="contact-email-text-btn">Close</Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailForm;
